import {Base, BaseProps} from '@studiometa/js-toolkit';
import {gsap} from 'gsap';
import {registerBurnInEffect, registerBurnOutEffect} from '../../utils/gsapEffects';

/**
 * Represents the properties for the Preloader component.
 * @interface PreloaderProps
 * @extends BaseProps
 */
interface PreloaderProps extends BaseProps {
  $refs: {
    wrapper: HTMLElement;
    content: HTMLElement;
    logo: HTMLElement;
    textTop: HTMLElement;
    textBottom: HTMLElement;
    progress: HTMLElement;
    progressGraphic: HTMLElement;
    line: HTMLElement;
    top: HTMLElement;
    bottom: HTMLElement;
  },
}

/**
 * Class representing a Preloader component, used for overlay effects.
 * @extends {Base<PreloaderProps>}
 */
export class Preloader extends Base<PreloaderProps> {
  /**
   * Component config.
   */
  static config = {
    name: 'Preloader',
    refs: [
      'wrapper',
      'content',
      'logo',
      'textTop',
      'textBottom',
      'progress',
      'progressGraphic',
      'line',
      'top',
      'bottom'
    ],
    emits: ['preloaderCompleted']
  };

  /**
   * Returns a boolean value indicating whether the preloader is loaded or not.
   * @returns {boolean} - True if the preloader is loaded, false otherwise.
   */
  get isPreloaderLoaded(): boolean {
    return sessionStorage.getItem('Preloader') === 'loaded';
  }

  /**
   * Represents the offset value.
   * @type {number}
   */
  private offset: number = 0;

  /**
   * Represents a numerical value, stored in variable y.
   * @type {number}
   * @default 0
   */
  private y: number = 0;

  /**
   * Represents a number variable.
   * @type {number}
   * @default 0
   */
  private x: number = 0;

  /**
   * Represents an array of HTML elements.
   * @typedef {Array<HTMLElement>} HTMLElement[]
   */
  private text: HTMLElement[];

  /**
   * Represents a collection of DOM elements.
   * @typedef {NodeListOf<Element>} NodeList
   */
  private textTopChars: NodeListOf<Element>;

  /**
   * Represents a collection of DOM elements obtained by selecting the bottommost elements on the page.
   * This variable is of type NodeListOf<Element>.
   *
   * @typedef {NodeListOf<Element>} textBottomChars
   */
  private textBottomChars: NodeListOf<Element>;

  /**
   * Represents a video element or null.
   * @type {HTMLVideoElement | HTMLElement | null}
   */
  private video: HTMLVideoElement | HTMLElement | null = null;

  /**
   * Sets up the component by adding an event listener to handle 'PreloaderAction' events.
   * @this {Preloader & Base<PreloaderProps>}
   */
  private mounted() {
    if (this.isPreloaderLoaded) {
      gsap.to([this.$refs.top, this.$refs.bottom], {
        autoAlpha: 0,
        duration: 0.5,
        onComplete: () => {
          this.setReady();
        }
      })
      return;
    }

    this.video = document.querySelector('[data-component="Hero"] [data-ref="video"]')
    this.offset = this.$refs.content && (window.innerHeight - this.$refs.content.offsetHeight) * 0.5;
    this.y = window.innerHeight * 0.5 - this.$refs.progress.offsetTop;
    this.x = this.$refs.progress.offsetWidth;

    gsap.set(document.documentElement, { height: '100vh', width: '100wh', overflow: 'hidden' });
    gsap.set(this.$refs.content, { y: this.offset });
    gsap.set(this.$refs.progressGraphic, { xPercent: -100 });
    gsap.set(this.$refs.line, { xPercent: -50 });
    gsap.set(this.$refs.line, { opacity: 1 });

    registerBurnInEffect();
    registerBurnOutEffect();

    setTimeout(() => {
      this.animate();
    }, 500)
  }

  /**
   * Splits the content of an element into individual letters.
   * @this {Preloader & Base<PreloaderProps>}
   * @param {HTMLElement} element - The element containing the text content to split.
   * @return {void}
   */
  private splitWordsIntoLetters(element: any): void {
    const words = element.textContent.split(' ');
    element.innerHTML = '';

    for (let i = 0; i < words.length; i += 1) {
      const word = words[i].replace(/\S/g,"<tg-letter class='inline-block' data-tg-letter-effect style='will-change:transform'>$&</tg-letter>");
      element.innerHTML += `<span">${word}</span>`;
      if (i + 1 < words.length) {
        element.innerHTML += '\xa0';
      }
    }
  }

  /**
   * Animates the logo and progress graphic.
   * @this {Preloader & Base<PreloaderProps>}
   * @private
   * @return {void}
   */
  private animateLogoAndProgressGraphic(): void {
    gsap.to(this.$refs.wrapper, {
      autoAlpha: 1,
      duration: 0.2
    })

    gsap.to(this.$refs.logo, {
      scale: 1,
      duration: 0.4,
      ease: 'back.out(1.4)',
    });

    gsap.to(this.$refs.progressGraphic, {
      xPercent: 0,
      duration: 2.2,
      ease: 'expo.in',
      delay: 0.7,
      onComplete: () => {
        this.animateHome();
      },
    });
  }

  /**
   * Animates the text using the GSAP library.
   * @this {Preloader & Base<PreloaderProps>}
   * @private
   * @returns {void}
   */
  private animateText(): void {
    const animateText = gsap.timeline({
      delay: 0.6
    });

    animateText.burnIn(this.textTopChars);
    animateText.fromTo(this.$refs.textTop, { x: 300 }, { duration: 3, x: -300, ease: 'slow(0.7, 0.6)' }, 0);
    animateText.burnIn(this.textBottomChars, '-=2.2');
    animateText.fromTo(this.$refs.textBottom, { x: -300 }, { duration: 2.7, x: 300, ease: 'slow(0.8, 0.7)' }, '<');
    animateText.burnOut(this.textTopChars, { from: 'end' }, '-=0.9');
    animateText.burnOut(this.textBottomChars, { from: 'start' }, '<+0.1');
  }

  /**
   * Animates the logo and progress graphic, splits words into letters, and animates the text.
   * @this {Preloader & Base<PreloaderProps>}
   * @return {void}
   */
  private animate(): void {
    this.animateLogoAndProgressGraphic();

    // Split Words
    this.text = [this.$refs.textTop, this.$refs.textBottom];
    this.text.forEach(this.splitWordsIntoLetters.bind(this));

    this.textTopChars = this.$refs.textTop.querySelectorAll('[data-tg-letter-effect]');
    this.textBottomChars = this.$refs.textBottom.querySelectorAll('[data-tg-letter-effect]');;

    this.animateText();
  }

  /**
   * Animates the home page elements.
   * @this {Preloader & Base<PreloaderProps>}
   * @returns {void}
   */
  animateHome(): void {
    // eslint-disable-next-line new-cap
    const tlAnimate = gsap.timeline({
      pause: false,
      delay: 0.2,
      onComplete: () => {
        this.setReady();
      },
    });

    // prettier-ignore
    tlAnimate
      .to(this.$refs.logo, {
        opacity: 0,
        duration: 0.6,
        ease: 'power3.in',
        stagger: 0.05,
      })
      .to(this.$refs.progress, {
        y: this.y,
        rotation: '180deg',
        duration: 1,
        ease: 'expo.inOut',
      }, '-=0.3')
      .set(this.$refs.progress, {opacity: 0,})
      .set(this.$refs.line, {
        width: this.x,
        opacity: 1,
      }, '-=0.1')
      .to(this.$refs.line, {
        width: '100%',
        duration: 0.8,
        ease: 'power3.inOut',
      }, '-=0.1')
      .to(this.$refs.line, {
        opacity: 0,
        duration: 0.6,
        ease: 'power3.out',
      }, '-=0.45')
      .to(this.$refs.top, {
        yPercent: -100,
        duration: 0.6,
        ease: 'expo.inOut',
      }, '-=0.5')
      .to(this.$refs.bottom, {
        yPercent: 100,
        duration: 0.6,
        ease: 'expo.inOut',
        onComplete: () => {
          gsap.set(document.documentElement, { clearProps: 'all' });
          sessionStorage.setItem('Preloader', 'loaded');
        },
      }, '-=0.6');
  }

  /**
   * Deletes the preloader HTML markup and starts playing the video if there is a previously stored state in the local storage.
   * @this {Preloader & Base<PreloaderProps>}
   * @returns {void}
   */
  setReady(): void {
    this.$el.outerHTML = '';
    this.$emit('preloaderCompleted');
  }

  public destroyed(): void {
  }
}
