import { gsap } from 'gsap';

const registerBurnInEffect = () => {
  gsap.registerEffect({
    name: 'burnIn',
    extendTimeline: true,
    defaults: {
      y: 0,
      x: 0,
      duration: 0.5,
      ease: 'none',
      from: 'start',
    },
    effect: (targets, config) => {
      gsap.set(targets, { filter: 'blur(0px) brightness(1)' });
      // prettier-ignore
      const tl = gsap.timeline()
      tl.from(targets, {
        filter: 'blur(20px) brightness(8)',
        scale: 0.8,
        rotation: -10,
        duration: config.duration,
        ease: config.ease,
        x: config.x,
        y: config.y,
        stagger: {
          each: 0.02,
          ease: 'none',
          from: config.from,
        },
      });

      // prettier-ignore
      tl.from(targets, {
        duration: 0.1,
        opacity: 0,
        ease: "none",
        stagger: {
          each:0.02,
          ease: 'power2',
          from: config.from,
        }
      }, 0)

      return tl;
    },
  });
};

const registerBurnOutEffect = () => {
  gsap.registerEffect({
    name: 'burnOut',
    extendTimeline: true,
    defaults: {
      y: 0,
      x: 0,
      duration: 0.3,
      delay: 0,
      from: 'start',
      ease: 'none',
    },
    effect: (targets, config) => {
      gsap.set(targets, { filter: 'blur(0px) brightness(1)' });
      const tl = gsap.timeline();
      // prettier-ignore
      tl.to(targets, {
        filter: "blur(30px) brightness(10)",
        scale: 0.3,
        rotation: 10,
        duration: config.duration,
        delay: config.delay,
        ease: config.ease,
        x: config.x,
        y: config.y,
        clearProps: 'filter',
        stagger:{
          each: 0.01,
          ease: 'none',
          from: config.from
        }})

      // prettier-ignore
      tl.to(targets, {
        duration: 0.1,
        delay: config.delay,
        opacity: 0,
        ease: 'none',
        stagger:{
          each:0.01,
          ease:"power2",
          from:config.from
        }
      }, 0)

      return tl;
    },
  });
};

export { registerBurnInEffect, registerBurnOutEffect };
