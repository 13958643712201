import {Base, BaseProps} from '@studiometa/js-toolkit';
import {gsap} from 'gsap';

/**
 * Represents the properties for the CapItem component.
 * @interface CapItemProps
 * @extends BaseProps
 */
interface CapItemProps extends BaseProps {
  $refs: {
    hover: HTMLElement;
    number: HTMLElement;
    cover: HTMLElement;
    content: HTMLElement;
    icon: HTMLElement;
    title: HTMLElement;
    text: HTMLElement;
    arrow: HTMLElement;
  },
}

/**
 * @class
 * @classdesc Class representing a CapItem component, used for overlay effects.
 * @extends Base<CapItemProps>
 */
export class CapItem extends Base<CapItemProps> {
  /**
   * Component config.
   */
  static config = {
    name: 'CapItem',
    refs: [
      'hover',
      'number',
      'cover',
      'content',
      'icon',
      'title',
      'text',
      'arrow',
    ],
  };

  /**
   * The gsap timeline.
   * @typedef {gsap.core.Timeline} timeline
   */
  private timeline: gsap.core.Timeline | null;

  /**
   * Initializes component.
   * @this {CapItem & Base<CapItemProps>}
   */
  mounted(): void {
    this.timeline = gsap.timeline({paused: true});
    this.initTimeline();
  }

  /**
   * Initializes the GSAP timeline for animations.
   * @this {CapItem & Base<CapItemProps>}
   */
  initTimeline(): void {
    this.timeline
      .to(this.$refs.cover, {scaleY: 0, duration: 0.5, ease: "expo.inOut"})
      .to([this.$refs.icon, this.$refs.title, this.$refs.arrow], {color: '#fff', duration: 0.5}, 0) // Start at the same time as the first animation
      .to(this.$refs.text, {opacity: 0, duration: 0.3}, 0) // Start at the same time as the first animation
      .to(this.$refs.arrow, {
        top: () => this.$refs.title.offsetTop + this.$refs.title.offsetHeight + 10,
        delay: 0.08,
        duration: 0.4
      }, 0);
  }

  /**
   * Executes animation effects when the mouse enters the component.
   * @this {CapItem & Base<CapItemProps>}
   * @method onMouseenter
   */
  onMouseenter() {
    this.timeline.play();
  }

  /**
   * Performs animation and styling when the mouse leaves the element.
   * @this {CapItem & Base<CapItemProps>}
   * @method onMouseleave
   * @returns {void}
   */
  onMouseleave() {
    this.timeline.reverse();
  }

  /**
   * Destroys the timeline by calling the kill() method.
   * @this {CapItem & Base<CapItemProps>}
   */
  public destroyed(): void {
    this.timeline.kill();
    this.timeline = null;
  }
}
