import {Base, BaseProps} from '@studiometa/js-toolkit';

interface BreadcrumbsProps extends BaseProps {
  $options: {
    minItemsLeft: Number;
    minItemsRight: Number;
  },
  $refs: {
    back: HTMLElement;
    staticItems: HTMLElement[];
    expandableItems: HTMLElement[];
    ellipsis: HTMLElement;
    ellipsisButton: HTMLElement;
  }
}

/**
 * Class representing a Breadcrumbs component.
 * @extends {Base<BreadcrumbsProps>}
 */
export default class Breadcrumbs extends Base<BreadcrumbsProps> {
  /**
   * Component config.
   */
  static config = {
    name: 'Breadcrumbs',
    refs: ['back', 'staticItems[]', 'expandableItems[]', 'ellipsis', 'ellipsisButton'],
    options: {
      minItemsLeft: Number,
      minItemsRight: Number,
    },
  };

  /**
   * Initialize component.
   * @this {Breadcrumbs & BreadcrumbsProps}
   * @public
   */
  mounted() {
    const visibilityMap = this.computeVisibilityMap();

    if (!visibilityMap) return;

    if (visibilityMap.expanded === true) {
      this.handleFullExpand();
    } else {
      this.handlePartialExpand(visibilityMap);
    }
  }

  /**
   * Event handler for ellipsis button click.
   * @this {Breadcrumbs & BreadcrumbsProps}
   * @private
   * @return {void}
   */
  private onEllipsisButtonClick() {
    this.handleFullExpand();
  }

  /**
   * Compute visibility of items.
   * @this {Breadcrumbs & BreadcrumbsProps}
   * @private
   * @returns {Object} - Returns an object with visibility information.
   */
  private computeVisibilityMap() {
    if (!Array.isArray(this.$refs.expandableItems) || this.$refs.expandableItems.length === 0) {
      return { expanded: true };
    }

    const wrapperWidth = Math.floor(this.$el.getBoundingClientRect().width);
    const staticItems = Array.isArray(this.$refs.staticItems) ? this.$refs.staticItems : [this.$refs.staticItems];
    const expandableItems = Array.isArray(this.$refs.expandableItems) ? this.$refs.expandableItems : [this.$refs.expandableItems];
    const allItems = staticItems.concat(expandableItems) as HTMLElement[];
    const allItemsWidth = allItems.reduce((totalWidth: number, item: HTMLElement) => totalWidth + item.offsetWidth, 0);

    if (allItemsWidth <= wrapperWidth) {
      return { expanded: true };
    }

    const ellipsisWidth = (this.$refs.ellipsis as HTMLElement)?.offsetWidth || 0;
    const incompressibleWidth = ellipsisWidth + staticItems.reduce((sum: number, item: HTMLElement) => sum + item.offsetWidth, 0);

    if (incompressibleWidth >= wrapperWidth) {
      return { expanded: false, isItemVisible: this.$refs.expandableItems.map(() => false) };
    }

    let visibleItemsWidth = 0;
    const isItemVisible = this.$refs.expandableItems.map((item) => {
      visibleItemsWidth += item.offsetWidth;
      return visibleItemsWidth + incompressibleWidth <= wrapperWidth;
    });

    return {
      expanded: false,
      isItemVisible,
    };
  }

  /**
   * Handle partial expansion of breadcrumbs.
   * @this {Breadcrumbs & BreadcrumbsProps}
   * @private
   * @param {Object} visibilityMap - The visibility map of items.
   * @return {void}
   */
  private handlePartialExpand(visibilityMap: { expanded: boolean; isItemVisible?: undefined; } | { expanded: boolean; isItemVisible: any; }) {
    if (!visibilityMap) return;
    this.onPartialExpand(visibilityMap.isItemVisible);
  }

  /**
   * Handle full expansion of breadcrumbs.
   * @this {Breadcrumbs & BreadcrumbsProps}
   * @private
   * @return {void}
   * The method does not return a value.
   */
  private handleFullExpand(): void {
    this.onFullExpand();
  }

  /**
   * Callback for partial expansion.
   * This method can be overridden in a subclass or instance if custom behavior is needed.
   * @this {Breadcrumbs & BreadcrumbsProps}
   * @private
   * @param {boolean[]} isItemVisible - Array indicating visibility of each item.
   * @return {void}
   */
  private onPartialExpand(isItemVisible: boolean[]): void {
    const expandableItems = Array.isArray(this.$refs.expandableItems) ? this.$refs.expandableItems : [this.$refs.expandableItems];

    expandableItems.forEach((item: HTMLElement, index: number) => {
      item.setAttribute('aria-hidden', isItemVisible[index] ? 'false' : 'true');
      item.classList.add(isItemVisible[index]? '' : 'hidden');
    });
  }

  /**
   * Callback for full expansion.
   * This method can be overridden in a subclass or instance if custom behavior is needed.
   * @this {Breadcrumbs & BreadcrumbsProps}
   * @private
   * @return {void}
   */
  private onFullExpand(): void {
    const ellipsisItems = Array.isArray(this.$refs.ellipsis) ? this.$refs.ellipsis : [this.$refs.ellipsis];
    ellipsisItems.forEach((item: HTMLElement) => {
      if (item) {
        item.setAttribute('aria-hidden', 'true');
        item.classList.add('hidden');
      }
    });

    const expandableItems = Array.isArray(this.$refs.expandableItems) ? this.$refs.expandableItems : [this.$refs.expandableItems];
    expandableItems.forEach((item: HTMLElement) => {
      item.setAttribute('aria-hidden', 'false');
      item.classList.remove('hidden');
    });
  }
}
