import {Base, BaseProps} from '@studiometa/js-toolkit';
import {CapItem} from "./CapItem";

/**
 * Represents the properties for the Cap component.
 * @interface CapProps
 * @extends BaseProps
 */
interface CapProps extends BaseProps {
  $children: {
    CapItem: CapItem[];
  },
}

/**
 * Class representing a Cap component, used for overlay effects.
 * @extends {Base<CapProps>}
 */
export default class Cap extends Base<CapProps> {
  /**
   * Component config.
   */
  static config = {
    name: 'Cap',
    components: {CapItem},
  };

  private baseHeight = 0

  /**
   * Sets up the component by adding an event listener to handle 'CapAction' events.
   * @this {Cap & Base<CapProps>}
   */
  private mounted() {
    if (this.$children.CapItem.length > 1) {
      this.setGridHeight();
    }
  }

  /**
   * Sets the height of the grid elements.
   * @this {Cap & Base<CapProps>}
   * @returns {void} The method does not return any value.
   */
  setGridHeight() : void {
    this.$children.CapItem.forEach((item, index) => {
      (item.$el.querySelector('div:first-child') as HTMLElement).style.height = '';

      if (item.$el.offsetHeight > this.baseHeight) {
        this.baseHeight = item.$el.offsetHeight;
      }
    });

    this.$children.CapItem.forEach((item, index) => {
      (item.$el.querySelector('div:first-child') as HTMLElement).style.height = this.baseHeight + 'px';
    })
  }
}
